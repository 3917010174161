<template>
  <div class="auth-wrapper auth-v2" :style="'background-image: url(' + bgImg + '); background-size: cover;'">
    <b-row class="auth-inner m-0" v-if="!modalShow">
      <b-col lg="8" offset-lg="2" class="d-flex align-items-center px-2 p-lg-5 text-white">
        <b-row style="min-width: 70rem">
          <b-col cols="6" class="px-xl-2 mx-auto">
            <div class="px-3 py-2 bg-white rounded">
              <div style="margin-bottom: 20px">
                <localeLogin />
              </div>
              <validation-observer ref="loginForm" #default="{ invalid }">
                <b-form class="auth-login-form mt-2" @submit.prevent="login">
                  <b-form-group :label="$t('login.email')" label-for="login-email">
                    <validation-provider #default="{ errors }" name="Email" vid="email" rules="required">
                      <b-form-input id="login-email" v-model="userEmail" :state="errors.length > 0 ? false : null"
                        name="login-email" placeholder="john@example.com" :disabled="modalShow" />
                      <small class="text-danger">
                        {{
                          errors.length > 0
                          ? $t("login.the_email_field_is_required")
                          : null
                        }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="login-password">
                        <p>{{ $t("login.password") }}</p>
                      </label>
                    </div>
                    <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
                      <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                        <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null"
                          class="form-control-merge" :type="passwordFieldType" name="login-password"
                          :placeholder="$t('login.password')" :disabled="modalShow" />
                        <b-input-group-append is-text>
                          <feather-icon class="cursor-pointer" color="#000" :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">
                        {{
                          errors.length > 0
                          ? $t("login.the_password_field_is_required")
                          : null
                        }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                  <div class="mt-1" v-if="modalShowSelect">
                    <div class="d-flex justify-content-between">
                      <label for="login-password">{{
                        $t("login.select_method_the_type_of_authentication")
                      }}</label>
                    </div>
                    <b-form-select v-model="selectedTypeVerify" :options="options"></b-form-select>
                  </div>
                  {{ selectedTypeVerify }}
                  <b-button type="submit" variant="danger" class="mt-2" block :disabled="invalid || isLoading">
                    {{ $t("login.get_into") }}
                  </b-button>
                </b-form>
              </validation-observer>
            </div>
          </b-col>
          <b-col cols="6" class="px-xl-2 mx-auto d-flex flex-column justify-content-center align-items-start">
            <img :src="logoUrl" alt="sbo-logo" style="height: 10vw;">
            <!-- <img :src="logoUrl" alt="konica-logo" style="height: 20vw;"> -->
            <!-- <p class="display-4">
              KONICA <br />
              MINOLTA
            </p>
            <p class="lead">SECURITY WAS NEVER SO EFFECTIVE</p> -->
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="auth-inner m-0" v-if="modalShow">
      <b-col lg="8" offset-lg="3" class="d-flex align-items-center px-2 p-lg-5 text-white">
        <b-row style="min-width: 70rem">
          <b-col cols="6" class="px-xl-2 mx-auto" v-if="!change_type_auth">
            <b-row>
              <b-col cols="12">
                <div class="px-3 py-2 bg-white rounded">
                  <validation-observer ref="loginForm" #default="{ invalid }">
                    <b-form inline class="auth-login-form" @submit.prevent="login">
                      <b-row>
                        <b-col cols="12"
                          class="px-xl-2 mx-auto d-flex flex-column justify-content-start align-items-start mb-1">
                          <localeLogin />
                        </b-col>
                        <b-col cols="12"
                          class="px-xl-2 mx-auto d-flex flex-column justify-content-center align-items-center">
                          <p class="lead" style="color: black">
                            {{ $t("auth_two_factor.title") }}
                          </p>
                        </b-col>
                        <b-col cols="12"
                          class="px-xl-2 mx-auto d-flex flex-column justify-content-center align-items-center mt-2">
                          <div class="input-container">
                            <input class="square-input" type="text" v-model="code1" maxlength="1" ref="code1"
                              @input="moveToNext($event.target, 'code2')" />
                            <input class="square-input" type="text" v-model="code2" maxlength="1" ref="code2"
                              @input="moveToNext($event.target, 'code3')" />
                            <input class="square-input" type="text" v-model="code3" maxlength="1" ref="code3"
                              @input="moveToNext($event.target, 'code4')" />
                            <input class="square-input" type="text" v-model="code4" maxlength="1" ref="code4"
                              @input="moveToNext($event.target, 'code5')" />
                            <input class="square-input" type="text" v-model="code5" maxlength="1" ref="code5"
                              @input="moveToNext($event.target, 'code6')" />
                            <input class="square-input" type="text" v-model="code6" maxlength="1" ref="code6"
                              @input="login(), moveToNext($event.target, 'code1')" />
                          </div>
                        </b-col>
                        <b-col cols="12">
                          <b-row v-if="showContador">
                            <b-col cols="6"
                              class="px-xl-2 mx-auto d-flex flex-column justify-content-end align-items-end mt-3">
                              <div class="mt-1">
                                <b-link @click="decreaseCounter" v-if="showContador" style="text-decoration: underline">{{
                                  $t("login.resend_code") }}
                                </b-link>
                                <span v-else style="color: black">{{ $t("login.you_can_resend_the_code_in_5_min") }}({{
                                  minutecounter
                                }}</span>
                              </div>
                            </b-col>
                            <b-col cols="6"
                              class="px-xl-2 mx-auto d-flex flex-column justify-content-start align-items-start mt-3">
                              <div class="mt-1" v-if="more_option_auth">
                                <b-link @click="change_auth_factor()" style="text-decoration: underline">{{
                                  $t("auth_two_factor.change_authentication_method")
                                }}
                                </b-link>
                              </div>
                            </b-col>
                          </b-row>
                          <b-row v-else>
                            <b-col cols="6"
                              class="px-xl-2 mx-auto d-flex flex-column justify-content-end align-items-end mt-3">
                              <div class="mt-1">
                                <span style="color: black">{{ $t("login.you_can_resend_the_code_in_5_min") }}({{
                                  minutecounter
                                }}s)</span>
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-form>
                  </validation-observer>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" class="px-xl-2 mx-auto" v-else>
            <b-row>
              <b-col cols="12">
                <div class="px-3 py-2 bg-white rounded">
                  <validation-observer ref="loginForm" #default="{ invalid }">
                    <b-form inline class="auth-login-form" @submit.prevent="login">
                      <b-row>
                        <b-col cols="12"
                          class="px-xl-2 mx-auto d-flex flex-column justify-content-start align-items-start mb-1">
                          <localeLogin />
                        </b-col>
                        <b-col cols="12"
                          class="px-xl-2 mx-auto d-flex flex-column justify-content-center align-items-center">
                          <p class="lead" style="color: black">
                            {{ $t("auth_two_factor.title") }}
                          </p>
                        </b-col>
                        <b-col cols="12" class="mt-3">
                          <b-row>
                            <b-col cols="6"
                              class="px-xl-2 mx-auto d-flex flex-column justify-content-center align-items-center">
                              <p class="lead" style="color: black">
                                {{ $t("auth_two_factor.change_authentication_method") }}
                              </p>
                            </b-col>
                            <b-col cols="6"
                              class="px-xl-2 mx-auto d-flex flex-column justify-content-center align-items-center">
                              <b-form-select v-model="selectedTypeVerifyOther" :options="options"></b-form-select>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col cols="12"
                          class="px-xl-2 mx-auto d-flex flex-column justify-content-center align-items-center mt-1">
                          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm"
                            class="mb-75 mr-75 text-capitalize align-self-center" style="margin-top: 13px"
                            @click="change_auth_factor(), decreaseCounter()">
                            {{ $t("auth_two_factor.send") }}
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </validation-observer>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" class="px-xl-2 mx-auto d-flex flex-column justify-content-center align-items-start">
            <!-- <p class="display-4">
              KONICA <br />
              MINOLTA
            </p> -->
            <p class="display-4">
              OMNIVIEW <br />
              SBO
            </p>
            <p class="lead">SECURITY WAS NEVER SO EFFECTIVE</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import localeLogin from "@/@core/layouts/components/app-navbar/components/LocaleLogin.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import usePassport from "@/auth/passport/usePassport";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import i18n from "@/libs/i18n";
import Echo from 'laravel-echo';
import useAppConfig from "@core/app-config/useAppConfig";


export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    localeLogin,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      change_type_auth: false,
      more_option_auth: false,
      selectedTypeVerifyOther: "",
      code1: "",
      code2: "",
      code3: "",
      code4: "",
      code5: "",
      code6: "",
      selectedTypeVerify: "",
      resend: 0,
      showContador: true,
      minutecounter: 300,
      type: "",
      modalShow: false,
      modalShowSelect: false,
      code: "",
      status: "",
      userEmail: "",
      password: "",
      bgImg: require("@/assets/images/bg-psim.jpg"),
      logoUrl: require("@/assets/images/logo/logo_login.png"),
      // logoUrl: require("@/assets/images/konica-minolta-login-logo.png"),
      options: [
        { value: 1, text: "Email" },
        { value: 2, text: "Sms" },
      ],

      required,
      email,
      isLoading: false,
    };
  },
  computed: {
    local_login() {
      return this.$store.getters["locale_login/getItems"];
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    change_auth_factor() {
      this.change_type_auth = !this.change_type_auth;
      // this.decreaseCounter();
    },
    moveToNext(input, nextInputRef) {
      if (input.value.length >= input.maxLength) {
        this.$refs[nextInputRef].focus();
      }
    },
    moveFinal(input) {
      if (input.value.length >= input.maxLength) {
        setTimeout(function () {
          this.login();
        }, 2000);
      }
    },
    resendcode() {
      if (this.minutecounter > 0) {
        this.minutecounter--;
      } else {
        clearInterval(this.t);
        this.minutecounter = 300;
        this.showContador = true;
      }
    },
    decreaseCounter() {
      this.showContador = false;
      this.t = setInterval(this.resendcode, 1000);
      this.resend = 1;
      this.login();
    },
    login() {
      this.isLoading = true;
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          usePassport
            .login({
              email: this.userEmail,
              password: this.password,
              code: `${this.code1}${this.code2}${this.code3}${this.code4}${this.code5}${this.code6}`,
              resend: this.resend,
              selectedTypeVerify: this.selectedTypeVerifyOther,
            })
            .then((response) => {
              if (response.data.message == "Seleccione metodo de autenticacion") {
                this.selectedTypeVerify = 2;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: this.trans["login.select_method_the_type_of_authentication"],
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                this.modalShowSelect = true;
              }
              if (response.data.message == "Se envio codigo de autenticacion") {
                if (response.data.data.verify_sms && response.data.data.verify_email) {
                  this.more_option_auth = true;
                }
                this.type = response.data.data.type;
                if (this.type == "email") {
                  this.selectedTypeVerifyOther = 1;
                }
                if (this.type == "sms") {
                  this.selectedTypeVerifyOther = 2;
                }
                this.modalShow = true;
                let valor = this.local_login.locale;
                let notificacion;
                switch (valor) {
                  case "es":
                    notificacion = "Código de autenticación enviado";
                    break;
                  case "en":
                    notificacion = "Authentication code sent";
                    break;
                  case "pt":
                    notificacion = "Código de autenticação enviado";
                    break;
                }
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: notificacion,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
              }
              if (response.data.message == "Credenciales correctas") {
                const { data } = response.data;
                const userData = data.user;
                userData.ability = [];
                i18n.locale = userData.lang;
                store.commit("locale_login/setData", userData.lang);
                if (userData.video_wall) {
                  store
                    .dispatch("video_wall/ping", userData)
                    .then((response) => {
                      console.log(response);
                    })
                    .catch((response) => console.log(response));
                }
                if (userData.is_master_admin) {
                  userData.ability.push({
                    action: "manage",
                    subject: "all",
                  });
                } else {
                  userData.ability = data.user.abilities;
                  userData.ability.push({
                    action: "read",
                    subject: "Auth",
                  });

                  userData.ability.push({
                    action: "read",
                    subject: "Home",
                  });
                }
                usePassport.setToken(data.access_token);
                this.$ability.update(userData.ability);
                localStorage.setItem("userData", JSON.stringify(userData));
                store.dispatch("user/setUserDataFromLocalStorage", userData);

                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Hello ${userData.first_name}`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: "Welcome back",
                  },
                });

                window.Echo = new Echo({
                  broadcaster: 'socket.io',
                  host: `${process.env.VUE_APP_LARAVEL_ECHO_HOST}`,
                  transport: ['websocket'],
                  auth: {
                    headers: {
                      Authorization: `Bearer ${data.access_token}`
                    },
                  },
                })

                setTimeout(() => { this.$router.replace(getHomeRouteForLoggedInUser(userData)); }, 1000);
              }
              this.resend = 0;
            })
            .catch((error) => {
              if (
                error.response.data.message == "Codigo incorrecto" ||
                error.response.data.message == "Expiro el codigo"
              ) {
                if (error.response.data.message == "Codigo incorrecto") {
                  let valor = this.local_login.locale;
                  let notificacion;
                  switch (valor) {
                    case "es":
                      notificacion = "Código incorrecto";
                      break;
                    case "en":
                      notificacion = "Incorrect code";
                      break;
                    case "pt":
                      notificacion = "Código incorreto";
                      break;
                  }

                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: notificacion,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  });
                  this.code1 = "";
                  this.code2 = "";
                  this.code3 = "";
                  this.code4 = "";
                  this.code5 = "";
                  this.code6 = "";
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: this.trans["login.the_code_expired_request_a_new_code"],
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  });
                  this.code1 = "";
                  this.code2 = "";
                  this.code3 = "";
                  this.code4 = "";
                  this.code5 = "";
                  this.code6 = "";
                }
              } else {
                if (error.response.data.message == "No existe el usuario") {
                  let valor = this.local_login.locale;
                  let notificacion;
                  switch (valor) {
                    case "es":
                      notificacion = "No existe el usuario";
                      break;
                    case "en":
                      notificacion = "The user does not exist";
                      break;
                    case "pt":
                      notificacion = "O usuário não existe";
                      break;
                  }
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: notificacion,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  });
                } else {
                  let valor = this.local_login.locale;
                  let notificacion;
                  switch (valor) {
                    case "es":
                      notificacion = "Error al iniciar sesión";
                      break;
                    case "en":
                      notificacion = "failed to login";
                      break;
                    case "pt":
                      notificacion = "não consegui fazer login";
                      break;
                  }

                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: notificacion,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  });
                }
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },
  },
  setup() {
    const { t } = useI18nUtils();
    const trans = {
      "login.select_method_the_type_of_authentication": t(
        "login.select_method_the_type_of_authentication"
      ),
      "login.authentication_code_sent": t("login.authentication_code_sent"),
      "login.incorrect_code": t("login.incorrect_code"),
      "login.the_code_expired_request_a_new_code": t(
        "login.the_code_expired_request_a_new_code"
      ),
    };

    const { skin } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.remove("dark-layout");

    return {
      trans,
      skin,
    };
  },
};
</script>

<style lang="scss">
.square-input {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  margin-right: 10px;
}

.input-container {
  max-width: 400px;
}

@import "@core/scss/vue/pages/page-auth.scss";
</style>
