<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"

  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item

      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="
        () => {
          $i18n.locale = localeObj.locale;
          flagChange(localeObj);
        }
      "
    >
      <b-img :src="localeObj.img" height="14px" width="22px" :alt="localeObj.locale" />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue";
import store from "@/store";
import { computed } from "@vue/composition-api";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },

  setup() {
    const currentLocale = computed(() => store.getters["locale_login/getItems"]);

    const flagChange = (localeObj) => {
      store.commit("locale_login/setData", localeObj);
    };

    const locales = [
      {
        locale: "en",
        img: require("@/assets/images/flags/en.png"),
        name: "English",
      },
      {
        locale: "es",
        img: require("@/assets/images/flags/es.png"),
        name: "Español",
      },
      {
        locale: "pt",
        img: require("@/assets/images/flags/pt.jpg"),
        name: "Português",
      },
    ];

    return {
      locales,
      flagChange,
      currentLocale,
    };
  },
};
</script>

<style scoped></style>
